<template lang="pug">
  header.header
    div.container
      div.header__body
        div.header__main
          a(href="/").header__logo.logo.transition
            img(:src="Logo" alt="logo")
          nav(@click="openMenu").navigation
            ul
              li(@click="removeLock")
                router-link(:to="{ name: 'HomePage', hash: '#contactUs' }").link Contact Us
              li
                a(href="https://apps.apple.com/us/app/translator-voice-camera-scan/id6452722816" target="_blank").btn
                  span Get the app
        button(type="button" @click="openMenu").hamburger
          span
          span
          span

</template>
<script>
// Images
import Logo from '@/assets/icons/logo.svg'
export default {
  name: 'HeaderPiece',
  data () {
    return {
      Logo,
      isScrolled: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    openMenu () {
      if (document.documentElement.clientWidth <= 767) {
        document.body.classList.toggle('lock')
        document.querySelector('.navigation').classList.toggle('active')
        document.querySelector('.hamburger').classList.toggle('active')
      }
    },
    removeLock () {
      document.body.classList.toggle('lock', window.innerWidth <= 767)
    },
    handleScroll () {
      const sections = document.querySelectorAll('section')
      let currentSection = ''
      sections.forEach(section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight
        if (window.pageYOffset >= sectionTop - (sectionHeight / 7) - 22) currentSection = section.getAttribute('id')
      })

      if (currentSection !== this.activeSection) this.activeSection = currentSection

      const header = document.querySelector('.header')

      if (window.pageYOffset > 50) header.classList.add('scrolled')
      else header.classList.remove('scrolled')
    }
  }
}
</script>
